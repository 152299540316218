<template>
  <link href="https://getbootstrap.com/docs/5.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossorigin="anonymous">
  <header class="d-flex justify-content-center py-3">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <router-link v-if="currentUser.userID" to="/chats"
                     class="nav-item nav-link">Сообщения
          <span v-if="counter.count > 0" class="messages_counter">{{ counter.count }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link v-if="currentUser.userID" to="/logout"
                     class="nav-item nav-link">Выход
        </router-link>
      </li>
      <li class="nav-item">
        <router-link v-if="!currentUser.userID" to="/login"
                     class="nav-item nav-link">Войти
        </router-link>
      </li>
      <li class="nav-item">
        <router-link v-if="!currentUser.userID" to="/registration"
                     class="nav-item nav-link">Регистрация
        </router-link>
      </li>
    </ul>
  </header>

  <router-view/>

  <vue-progress-bar></vue-progress-bar>
</template>

<script>
import AuthController from "./controllers/AuthController.js";
import { useCounterStore } from '@/stores/newChatMessagsCounter'
import { useUserStore } from '@/stores/userStore'

export default {
  data() {
    this.authController = new AuthController();
  },
  setup()  {
    const counter = useCounterStore()
    const currentUser = useUserStore()

    return {
      counter,
      currentUser
    }
  },
  mounted() {
    this.$Progress.finish();
    this.authController.me().then(response => {
      this.currentUser.login(response.data.data.main_app_user_id)
      this.currentUser.authChat(response.data.data.id)
      this.counter.setCounter(response.data.data.new_messages_chats)
    }).catch(() => {
      this.currentUser.logout()
    })

  },
  created() {
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  }
};
</script>

<style>

:root {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

body {
  padding: 1%;
  margin: 0 auto;
  background-color: #F5F5F5;
}

.loader {
  position: absolute;
  left: 45%;
  top: 25%;
  z-index: 1;
  width: 120px;
  height: 120px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.unread_message {
  padding: 5px;
  background-color: lightgrey;
}

.main_container {
  margin: 0 auto;
}

.container {
  width: 50%; margin: 0 auto;

  @media ~max-width: 768px {
    width: 100%;
  }
}

.discussions {
  width: 100%;
  height: 700px;
  display: block;
}

.discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #FAFAFA;
  border: solid 1px #E0E0E0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.discussions .search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E0E0E0;
}

.discussions .search .searchbar {
  height: 40px;
  background-color: #FFF;
  width: 70%;
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.discussions .search .searchbar input {
  margin-left: 15px;
  height: 38px;
  width: 100%;
  border: none;
  font-family: 'Montserrat', sans-serif;;
}

.discussions .search .searchbar *::-webkit-input-placeholder {
  color: #E0E0E0;
}

.discussions .search .searchbar input *:-moz-placeholder {
  color: #E0E0E0;
}

.discussions .search .searchbar input *::-moz-placeholder {
  color: #E0E0E0;
}

.discussions .search .searchbar input *:-ms-input-placeholder {
  color: #E0E0E0;
}

.discussions .message-active {
  width: 98.5%;
  height: 90px;
  background-color: #FFF;
  border-bottom: solid 1px #E0E0E0;
}

.discussions .discussion .photo {
  margin-left: 15px;
  display: block;
  width: 45px;
  height: 45px;
  background: #E6E7ED;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8BC34A;
  border-radius: 13px;
  border: 3px solid #FAFAFA;
}

.desc-contact {
  height: 43px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11pt;
  color: #515151;
}

.discussions .discussion .message {
  margin: 2px 0 0 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 9pt;
  color: #515151;
}

.timer {
  margin-left: 15%;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #BBB;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
}

.chat {
  width: 100%;
  border: solid 1px #E0E0E0;
}

.header-chat {
  background-color: #FFF;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.100);
  display: flex;
  align-items: center;
}

.chat .header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14pt;
}

.chat .header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 13pt;
  color: #515151;
}

.chat .header-chat .right {
  position: absolute;
  right: 40px;
}

.chat .messages-chat {
  padding: 25px 35px;
}

.chat .messages-chat .message {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
  display: block;
  width: 45px;
  height: 45px;
  background: #E6E7ED;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.chat .messages-chat .text {
  margin: 0 35px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
}

.text-only {
  margin-left: 45px;
}

.time {
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 85px;
}

.response-time {
  text-align: right;
  margin-right: 40px !important;
}

.response {
  float: right;
  margin-right: 0px !important;
  margin-left: auto; /* flexbox alignment rule */
}

.response .text {
  background-color: #e3effd !important;
}

.footer-chat {
  width: calc(65% - 66px);
  height: 80px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  border-top: 2px solid #EEE;

}

.chat .footer-chat .icon {
  margin-left: 30px;
  color: #C0C0C0;
  font-size: 14pt;
}

.chat .footer-chat .send {
  color: #fff;
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}

.chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 13pt;
  color: #515151;
}

.chat .footer-chat .right {
  position: absolute;
  right: 40px;
}

.write-message {
  border: none !important;
  width: 60%;
  height: 50px;
  margin-left: 20px;
  padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}

.footer-chat input *:-moz-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}

.footer-chat input *::-moz-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
  margin-left: 5px;
}

.footer-chat input *:-ms-input-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}

.clickable {
  cursor: pointer;
}

.messages_counter {
  flex-shrink: 0;
  min-width: 20px;
  max-width: 50px;
  padding: 0 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #99A2AD;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 20px;
  border-radius: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
  cursor: pointer;
  margin-left: 4px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
