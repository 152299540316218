import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            userID: localStorage.getItem('userId'),
            chatUserId: localStorage.getItem('chatUserId'),
        }
    },
    actions: {
        login(userId) {
            this.userID = userId
            localStorage.setItem('userId', userId)
        },
        authChat(chatUserId) {
            this.chatUserId = chatUserId
            localStorage.setItem('chatUserId', chatUserId)
        },
        logout() {
            this.userID = false;
            this.chatUserId = false;
            localStorage.removeItem('userId')
            localStorage.removeItem('access_token')
            localStorage.removeItem('chatUserId')
        }
    },
})