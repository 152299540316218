import axios from "axios";
import config from "../config.js";
import { token } from "@/helpers";
export default class AuthController {
    constructor() {}

    async login(data) {
        return axios({
            method: 'post',
            url: config.USERS_APP_HOST + '/login/',
            data: data
        })
    }

    registration(data) {
        return axios({
            method: 'post',
            url: config.USERS_APP_HOST + '/registration/',
            data: data
        })
    }

    async me() {
        return axios({
            method: 'get',
            url: config.CHAT_APP_HOST + '/users/me/',
            headers: token()
        })
    }

    logout() {
        return axios({
            method: 'post',
            url: config.USERS_APP_HOST + '/logout/',
            headers: token()
        })
    }

}
